/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './common';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "roster": {
        "nested": {
          "mds": {
            "nested": {
              "MerchantBillingData": {
                "fields": {
                  "subscriptionFeatureStatus": {
                    "type": "squareup.roster.common.SubscriptionFeatureStatus",
                    "id": 1,
                    "rule": "repeated"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.MerchantBillingData) {
	$root.addJSON(jsonData);
}

/**
 * Merchant app data for application=BILLING
 *
 * SOURCE MerchantBillingData @ squareup/roster/mds.proto at 653:1
 */
export const MerchantBillingData = $root.lookupType('squareup.roster.mds.MerchantBillingData');
fixType(MerchantBillingData);
Builder.createAndAttachToType(MerchantBillingData);
