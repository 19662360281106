import { MessengerService } from 'src/gen/squareup/messenger/v2/messenger_service';
import { MessengerService as MessengerServiceV3 } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MessengerAuxiliaryService } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';
import Cookies from 'js-cookie';
import { getServicesUrl, MULTIPASS_URL } from 'src/utils/url';
import {
  MethodDescriptorProto,
  MultipassCredentialsResponse,
} from 'src/MessengerTypes';
import Logger from 'src/Logger';
import { parseResponse } from 'src/utils/apiUtils';
import SqOneTrustService from './SqOneTrustService';
import GoogleMapsService from './GoogleMapsService';
import { BillingService } from 'src/gen/squareup/billing/service';
import {
  MessagesAuthenticationError,
  MessagesAuthorizationError,
} from 'src/types/Errors';

/**
 * Class that contains a reference to each of the services Messages is dependent on.
 */
class Services {
  csrfToken: Promise<string>;
  sqOneTrust: SqOneTrustService;
  messages: MessengerService;
  messagesV3: MessengerServiceV3;
  messagesAuxiliary: MessengerAuxiliaryService;
  googleMaps: GoogleMapsService;
  billing: BillingService;

  constructor() {
    this.csrfToken = this._fetchCsrfToken();
    this.sqOneTrust = new SqOneTrustService();
    this.messages = MessengerService.create(this._rpcImpl);
    this.messagesV3 = MessengerServiceV3.create(this._rpcImpl);
    this.googleMaps = new GoogleMapsService();
    this.billing = BillingService.create(this._rpcImpl);
    this.messagesAuxiliary = MessengerAuxiliaryService.create(this._rpcImpl);
  }

  /**
   * Executes a fetch request for JSON.
   *
   * @param {string} url
   * The URL to execute the request to.
   * @param {RequestInit} [options]
   * Options for the request.
   */
  fetchJson = async (url: string, options?: RequestInit): Promise<Response> => {
    const csrfToken = await this.csrfToken;

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    // add multipass credentials to this request
    headers.append('X-CSRF-Token', csrfToken);

    // Add the Onetrust headers to every request
    Object.entries(this.sqOneTrust.getCookieFilterHeaders()).forEach(
      ([headerName, headerValue]) => {
        headers.append(headerName, headerValue);
      },
    );

    const fetchRequest = new Request(url, {
      method: 'GET',
      credentials: 'include',
      headers,
      ...options,
    });

    return fetch(fetchRequest);
  };

  /**
   * Executes a fetch request for JSON and parses the response.
   * Useful if the full response is not required and only the parsed
   * body of the response is needed.
   *
   * @param {string} url
   * The URL to execute the request to.
   * @param {RequestInit} [options]
   * Options for the request.
   */
  fetchParsedJson = async (
    url: string,
    options?: RequestInit,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const response = await this.fetchJson(url, options);

    try {
      return response.json();
    } catch {
      // In testing, the response is a plain object, not a readable stream
      return response;
    }
  };

  /**
   * RPC Implementation for use with the Messenger & Rolodex services.
   *
   * @param {MethodDescriptorProto} method
   * Contains the endpoint name and its parent.
   * @param {Uint8Array} requestData
   * The serialized request data.
   * @param {(error: Error | null, response?: Response | Uint8Array | null) => void} callback
   * The generated code to be called.
   */
  private _rpcImpl = async (
    method: MethodDescriptorProto,
    requestData: Uint8Array,
    callback: (
      error: Error | null,
      response?: Response | Uint8Array | null,
    ) => void,
  ): Promise<void> => {
    const csrfToken = await this.csrfToken;

    const rpc = method.name;
    const service = method.parent.fullName.replace(/^\./, '');
    // sample URL: /services/squareup.conversations.MessengerService/GetConversations
    const url = getServicesUrl(service, rpc);

    const headers = new Headers();
    // application/x-protobuf is the only format tracon accepts
    // note: this is different from application/grpc-web+proto and application/grpc-web-text,
    // which is what open source code gen tools use
    headers.append('Accept', 'application/x-protobuf');
    headers.append('Content-Type', 'application/x-protobuf');
    // add multipass credentials to every request
    headers.append('X-CSRF-Token', csrfToken);

    // Add the Onetrust headers to every request
    Object.entries(this.sqOneTrust.getCookieFilterHeaders()).forEach(
      ([headerName, headerValue]) => {
        headers.append(headerName, headerValue);
      },
    );

    const fetchRequest = new Request(url, {
      body: requestData,
      credentials: 'include',
      headers,
      method: 'POST',
    });

    fetch(fetchRequest)
      .then(async (response: Response | Uint8Array) => {
        if (response instanceof Uint8Array) {
          if (typeof jest === 'undefined') {
            // This is unexpected, we should never get a Uint8Array as a response outside of
            // testing. Sentry.
            Logger.logWithSentry(
              'Services:_rpcImpl - Got Uint8Array response outside of testing. This should never happen',
              'error',
              {
                response,
              },
            );
          }
          // Case: Response is already a Uint8Array. This is expected in testing.
          callback(null, response);
        } else if (response.status === 401) {
          // Case: We got a 401 from Envoy SAFE. Throw an auth error that can be caught and handled
          // appropriately given the situation. Also note that this is here and not in a catch()
          // because from MDN, 4xx don't throw with fetch(). They return a Response with a status
          // code.
          callback(new MessagesAuthenticationError(response.statusText));
        } else if (response.status === 403) {
          // Case: We got a 403 from Envoy SAFE. Throw an auth error that can be caught and handled
          // appropriately given the situation. Also note that this is here and not in a catch()
          // because from MDN, 4xx don't throw with fetch(). They return a Response with a status
          // code.
          callback(new MessagesAuthorizationError(response.statusText));
        } else {
          callback(null, await parseResponse(response));
        }
      })
      .catch((error) => {
        callback(error, null);
      });
  };

  /**
   * Logic to fetch the CSRF Token depending on the environment.
   *
   * @returns {Promise<string>} - Resolves the CSRF Token.
   */
  private _fetchCsrfToken = async (): Promise<string> => {
    if (process.env.NODE_ENV !== 'development') {
      const token = Cookies.get('_js_csrf');
      if (token === undefined) {
        Logger.logWithSentry(
          'Services:_fetchCsrfToken - _js_csrf cookie is missing',
          'error',
        );
        return this._readCsrfTokenFromMetaTag();
      }
      return token;
    }

    // This calls an endpoint in the development server, which hits /mp/status internally
    // See setupProxy.js for the endpoint definition.
    const response = (await this.fetchParsedJson(MULTIPASS_URL, {
      method: 'GET',
    })) as MultipassCredentialsResponse;
    return response.csrfToken;
  };

  /**
   * Reads the CSRF Token from the meta tag on the page.
   *
   * @returns {string} - The CSRF Token.
   */
  private _readCsrfTokenFromMetaTag = (): string => {
    // TODO(wdetlor): Remove the logic to read the CSRF token from the meta tag.
    // Should always be available in the _js_csrf cookie but this is left here temporarily
    // in case there is an edge case we aren't aware of where its not. If we don't get any errors
    // related to reading the token from the _js_csrf cookie, we should remove this code.
    const csrfMetaTag: HTMLMetaElement | null = document.querySelector(
      'meta[name="csrf-token"]',
    );
    if (csrfMetaTag === null || !csrfMetaTag?.content) {
      Logger.logWithSentry(
        'Services:_fetchCsrfToken - CSRF token missing from meta tag.',
        'error',
        {
          csrfMetaTag,
          token: csrfMetaTag?.content,
        },
      );
      return '';
    }
    return csrfMetaTag.content;
  };
}

export default Services;
