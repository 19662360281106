import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MarketButton,
  MarketButtonDropdown,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import MoreIcon from 'src/svgs/MoreIcon';
import { getCustomerInDirectoryLink } from 'src/utils/url';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import MediumSwitcher from 'src/components/MediumSwitcher/MediumSwitcher';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type MoreMenuProps = {
  customer: Contact;
};

/**
 * The menu containing additional actions the user can take while on the customer detail page.
 *
 * @param {Contact} customer
 * The customer data object for the details page that this menu is rendering on.
 */
const MoreMenu = observer(({ customer }: MoreMenuProps) => {
  const { event, transcriptView } = useMessengerControllerContext();
  const { t } = useTranslation();

  const viewProfileOnClick = (): void => {
    window.open(getCustomerInDirectoryLink(customer.token), '_blank');
    event.track('Click View Profile', {
      customer_id: customer.token,
    });
  };

  return (
    <MarketButtonDropdown popoverPlacement="bottom-end" noCaret>
      <MarketButton slot="trigger">
        <MoreIcon slot="icon" />
      </MarketButton>
      <MarketList slot="content">
        {transcriptView.transcript.customerTokens.length > 1 && (
          <MediumSwitcher customer={customer} />
        )}
        <MarketRow
          key="view_profile"
          onClick={viewProfileOnClick}
          onKeyDown={(e) => onKeyDownEnter(e, viewProfileOnClick)}
        >
          <label slot="label">
            {t('TranscriptViewPage.more_menu.view_profile')}
          </label>
        </MarketRow>
      </MarketList>
    </MarketButtonDropdown>
  );
});

export default MoreMenu;
